"use client"

import "./styles.css"

import { FAQSectionData } from "@/utils/hardcodedData"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useState } from "react";

export default function FAQSection () {
  const [idOfSelectedFAQ, setIdOfSelectedFAQ] = useState(0)

  return (
    <section id="FAQ" className="w-full bg-darkGray py-10 text-white font-bold flex flex-col justify-between items-center">
      <h1 className="text-center text-[25px] mb-6">
        {FAQSectionData.title}
      </h1>
      <div className="w-[80%] max-w-[670px] z-20">
          {FAQSectionData.questionsAndAnswers.map((questionAndAnswer, index) => {
            const isThisFAQSelected = questionAndAnswer.id === idOfSelectedFAQ

            return (
              <div
                className="question-answer-cont z-40 relative w-full rounded-md my-4 overflow-hidden"
                key={index}
                onClick={() => {
                  if(idOfSelectedFAQ === questionAndAnswer.id) {
                    setIdOfSelectedFAQ(0)
                  } else {
                    setIdOfSelectedFAQ(questionAndAnswer.id)
                  }
                }}
              >

                <div className="question-rectangle z-20 w-full px-6 bg-gray rounded-md py-4 mb-4 flex justify-between
                items-center transition-all duration-200">

                  <p>
                    {questionAndAnswer.question}
                  </p>

                  <ArrowForwardIosIcon className={`w-[18px] h-[18px] transition-all duration-200 ${isThisFAQSelected ? "arrow-active" : ""}`} />

                </div>

                <p className={`answer-text z-10 absolute top-[-300%] px-6 text-lightGray text-[15px] ${isThisFAQSelected ? "relative" : ""}`}>
                  {questionAndAnswer.answer}
                </p>

              </div>
            )
          })}
      </div>
    </section>
  )
}