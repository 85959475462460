"use client"

import FAQSection from "@/components/HomeOnlyComponents/FAQSection/FAQSection";
import CompanyAlliesLogosSection from "@/components/HomeOnlyComponents/CompanyAlliesLogosSection/CompanyAlliesLogosSection";
/* import Statistics from "@/components/HomeOnlyComponents/Statistics/Statistics"; */
import GreenOutlinedButton from "@/components/buttons/GreenOutlinedButton";
import WhiteOutlinedButton from "@/components/buttons/WhiteOutlinedButton";
import Navbar from "@/components/nav/Navbar";

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Footer from "@/components/Footer/Footer";
/* import { useState } from "react";
import LoginModal from "@/components/HomeOnlyComponents/LoginModal/LoginModal"; */
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import "./globals.css"

export default function Home() {
  //code neccessary only when using login modal
  /* const [loginModalOpenState, setLoginModalOpenState] = useState(false) */

  const [isColombianHostname, setIsColombianHostname] = useState(false)
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const isColombiaData = window.location.hostname === "mascolab.com" || window.location.hostname === "localhost";
    setIsColombianHostname(isColombiaData)
    console.log({isColombiaData})

    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const router = useRouter()

  const homeBgVideoURL = width > 1500 ? "https://res.cloudinary.com/mascolab/video/upload/v1727461839/Video_pa%CC%81gina_web_j0wchn.mp4" : "https://res.cloudinary.com/mascolab/video/upload/v1727392835/home-bg-video_p5fqpi.mp4"

  return (
    <div className="relative h-screen">
      <Navbar /* setLoginModalOpenState={setLoginModalOpenState} */ />

      <main className="main-section min-h-screen relative transition-all">
        {/* code neccessary only when using login modal */}
        {/* <LoginModal open={loginModalOpenState} setLoginModalOpenState={setLoginModalOpenState} /> */}
        <section className=" w-full bg-black">
          <div className="home-main-section h-screen relative mx-auto flex overflow-hidden flex-col items-center justify-center w-screen min-h-[400px] px-4 text-white pt-[76px] pb-14
            sm:py-10 sm:pt-[130px]
            md:px-10 md:py-20 md:pt-[150px]
            lg:flex-row lg:justify-start lg:px-20 lg:min-h-[500px]
            2xl:px-0"
          >

            {/* home bg video */}
            <video
              id="home-bg-video"
              className="hidden absolute min-h-[1000px] min-w-[1800px] top-0 bottom-[200px]
                sm:block
                lg:-left-[400px]
                xl:-left-0 
                2xl:-left-[1000px] 2xl:min-w-[3000px] 2xl:-top-[300px]
                "
              autoPlay
              muted
              loop
              preload="auto"
              src={homeBgVideoURL}
            >
              Tu navegador no admite el elemento <code>video</code>.
            </video>

            {/* bg video low opacity black overlay */}
            <div className="home-bg-video-overlay z-20 absolute top-0 right-0 left-0 bottom-0">

            </div>

            <div className="main-section-text w-full mx-auto max-w-[1568px] z-20 xl:w-[90%]">

              <div className="w-full max-w-[560px] mx-auto mb-10 md:-translate-y-10 lg:mx-0 lg:max-w-[620px]">

                <div className="w-[98%] mx-auto mb-10 ml-2">

                  <h1 className="font-bold text-[30px] leading-10 mb-2 sm:text-[45px] md:text-[50px] md:leading-[50px] lg:text-[55px] lg:leading-[60px]">
                    Lleva tus diagnósticos a otro nivel con

                    <strong className="gradient-glowing-green-text"> PCR</strong>

                  </h1>

                  <p>
                    Con una efectividad del 99,9% la tecnología RT qPCR junto con el método Sonda TaqMan,
                    se considera de las más confiables y sensibles para diagnosticar material genético viral.
                  </p>

                </div>

                <div className="w-full lg:flex">
                  <GreenOutlinedButton
                    onClickFunc={() => {
                      const userSession = undefined
                      
                      if(userSession) {
                        //redirect to recetary
                      } else {
                        //code neccessary only when using login modal
                        /* setLoginModalOpenState(true) */

                        //this redirects to the existing login website of mascolab
                      if(isColombianHostname) {
                        router.push("https://mascolab.com/recetario/")
                      } else {
                        router.push("https://mascolab.mx/recetario/")
                      }
                      }
                    }}
                    className="py-4 bg-green rounded-[35px] text-white mx-0 font-bold sm:text-[18px] mb-4 lg:mb-0 lg:w-[208px] lg:h-[64px] hover:bg-white hover:text-black hover:border-white hover:-translate-y-2"
                  >
                      Enviar recetario
                  </GreenOutlinedButton>

                  <WhiteOutlinedButton
                    className="home-white-button bg-lowOpacityWhite rounded-[35px] py-4 mx-0 font-bold sm:text-[18px]
                    lg:mb-0 lg:w-[208px] lg:h-[64px] lg:mx-[10px]
                    hover:bg-mediumOpacityWhite
                    "
                    onClickFunc={() => {
                      router.push("https://drive.google.com/file/d/1gRDpCefvx9kVmt5FuJHxcgaMMGp582tQ/view")
                    }}
                  >
                    Ver portafolio
                    <ArrowForwardIosIcon className="arrow-icon" sx={{
                      width: "12px",
                      height: "12px",
                      marginLeft: "6px",
                      transition: "all 200ms ease-in-out"
                    }}/>
                  </WhiteOutlinedButton>
                </div>

              </div>

            </div>

            {/* component where we show metrics about the company */}
            {/* <Statistics /> */}
            

            <div className="absolute bottom-0 left-0  w-screen z-20 bg-white">
              {/* component where we display the logos of company allies */}
              <CompanyAlliesLogosSection />
            </div>
          </div>

          <FAQSection />

        </section>

        <Footer isColombianHostname={isColombianHostname} />
      </main>
    </div>
  );
}
