import "./styles.css"

import { coFooterData, mxFooterData } from "@/utils/hardcodedData";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import facebookIcon from "../../../public/icons/facebook.svg"
import instagramIcon from "../../../public/icons/instagram.svg"
import tiktokIcon from "../../../public/icons/tik-tok.svg"
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";

function MxContactsAndLocationsSection () {
  return (
    <div className="max-w-[400px] w-full text-start flex flex-col items-center
      sm:flex-row sm:justify-start sm:items-start sm:max-w-[1100px]
    ">
      <div className="w-full py-4 mb-4 sm:w-[40%] md:w-[45%] lg:w-[50%]">
        <h2 className="font-bold mb-4">{mxFooterData.contactUs.title}</h2>
        {mxFooterData.contactUs.contacts.map((contact, index) => {
          return (
            <p className="text-lightGray my-1" key={index} >
              <strong className="text-white" >{contact.label}:</strong> {contact.value}
            </p>
          )
        })}
      </div>

      <div className="w-full flex justify-between sm:justify-evenly sm:ml-14">
        {mxFooterData.locations.map((location, index) => {
          return (
            <div className="w-[40%] py-4 sm:mr-10 sm:w-full" key={index}>

              <Link href={location.url}>
                <div className="mb-2 flex items-center justify-start location-title__cont">

                  <h2 className="font-bold location-data__title transition duration-200 ">{location.city}</h2>
                  <LocationOnOutlinedIcon className="location-data__icon w-4 transition duration-200 h-4 ml-1"/>

                </div>
              </Link>
            
              <p className="text-lightGray my-2" key={index}>Dirección: {location.address}</p>

            </div>
          )
        })}
      </div>
    </div>
  )
}

function CoContactsAndLocationsSection () {
  return (
    <div className="max-w-[400px] w-full text-start flex flex-col items-center
      sm:flex-row sm:justify-start sm:items-start sm:max-w-[1100px]
    ">
      <div className="w-full py-4 mb-4 sm:w-[40%] md:w-[45%] lg:w-[50%]">
        <h2 className="font-bold mb-4">{coFooterData.contactUs.title}</h2>
        {coFooterData.contactUs.contacts.map((contact, index) => {
          return (
            <p className="text-lightGray my-1" key={index}>
              <strong className="text-white" >{contact.label}:</strong> {contact.value}
            </p>
          )
        })}
      </div>

      <div className="w-full flex justify-between sm:justify-evenly sm:ml-14">
        {coFooterData.locations.map((location, index) => {
          return (
            <div className="w-[40%] py-4 sm:mr-10 sm:w-full" key={index}>

              <Link href={location.url}>
                <div className="mb-2 flex items-center justify-start location-title__cont">

                  <h2 className="font-bold location-data__title transition duration-200 ">{location.city}</h2>
                  <LocationOnOutlinedIcon className="location-data__icon w-4 transition duration-200 h-4 ml-1"/>

                </div>
              </Link>
            
              {location.workingTimes.map((workingTime, index) => {
                return (
                  <p className="text-lightGray my-2" key={index}>{workingTime.days}: {workingTime.time}</p>
                )
              })}

            </div>
          )
        })}
      </div>
    </div>
  )
}


export default function Footer ({ isColombianHostname
 }) {

  return (
    <footer id="footer" className="w-full bg-black px-4 py-4 text-white flex flex-col items-center">

      <div className="w-full max-w-[1568px] relative flex flex-col items-start px-4 md:px-10 lg:px-16 xl:w-[92%] 2xl:w-[90%] 2xl:px-0 2xl:mt-10">

        <h1 className="my-4 font-bold text-[25px]
          lg:my-10
          2xl:text-[20px] 2xl:absolute 2xl:bottom-8 2xl:right-0"
        >
          {coFooterData.companyName}
        </h1>

        <div className="w-full"
        >
          {isColombianHostname ?
            <CoContactsAndLocationsSection />
            :
            <MxContactsAndLocationsSection />
          }
        </div>
        <hr className="bg-lightGray w-full mb-4" />
        <div className="w-full flex xl:justify-between">
          <p className="text-lightGray text-center my-2">
            <strong className="text-white" >{coFooterData.copyRightBoldText}</strong> {coFooterData.copyRightNormalText}
          </p>

          <div className="hidden xl:flex justify-between items-center">

            <Link href={"https://www.tiktok.com/@mascolab0"}>
              <div className="social-media-icon__cont transiton duration-200 rounded-xl py-1">
                <Image src={tiktokIcon} width={40} height={40} alt="tiktok social media icon" className="mx-2" />
              </div>
            </Link>

            <Link href={"https://www.instagram.com/mascolab/"}>
              <div className="social-media-icon__cont transiton duration-200 rounded-xl py-1">
                <Image src={instagramIcon} width={40} height={40} alt="instagram social media icon" className="mx-2" />
              </div>
            </Link>

            <Link href={"https://www.facebook.com/laboratoriomascolab/"}>
              <div className="social-media-icon__cont transiton duration-200 rounded-xl py-1">
                <Image src={facebookIcon} width={40} height={40} alt="facebook social media icon" className="mx-2" />
              </div>
            </Link>

          </div>

        </div>

      </div>

    </footer>
  )
}