"use client"

import "./styles.css"

import animalCenterLogo from "../../../../public/logos/AnimalCenterLogo.svg"
import clinicaVeterinariaVillalobosLogos from "../../../../public/logos/vetVillalobosLogo.svg"
import unidadRenalPaolaBaezLogo from "../../../../public/logos/UnidadRenalLogo.svg"

import bioanalisisAnimalLogo from "../../../../public/logos/BioanalisisAnimal.svg"
import udcaLogo from "../../../../public/logos/U.D.C.A.svg"
import zoologicoCaliLogo from "../../../../public/logos/ZoologicoDeCali.svg"
import mevetLogo from "../../../../public/logos/Mevet.svg"
import laboratiorioCamLogo from "../../../../public/logos/CAM.svg"
import bioVetLogo from "../../../../public/logos/BiovetBGA.svg"
import CSALogo from "../../../../public/logos/C.S.A.svg"
import centroVeterinarioJuanCarlosBuiles from "../../../../public/logos/CentroVeterinarioJuanCarlosBuiles.svg"
import Image from "next/image"

export default function CompanyAlliesLogosSection () {
  return (
    <section className="logos-carousel">
      <div className="logos-carousel__slide__cont">

        <div className="logos-carousel__slide">

          <Image className="carousel-item" src={udcaLogo} alt={"UDCA logo"} />
          <Image className="carousel-item" id="logo-unidad-renal" src={unidadRenalPaolaBaezLogo} alt={"Unidad renal Paola Baez logo"} />
          <Image className="carousel-item" src={bioVetLogo} alt={"biovet logo"} />
          <Image className="carousel-item" src={bioanalisisAnimalLogo} alt={"bio analisis animal logo"} />
          <Image className="carousel-item" id="logo-animal-center" src={animalCenterLogo} alt={"animal center logo"} />
          <Image className="carousel-item" src={laboratiorioCamLogo} alt={"laboratiorio CAM logo"} />
          <Image className="carousel-item" src={zoologicoCaliLogo} alt={"zoologico de Cali logo"} />
          <Image className="carousel-item" src={CSALogo} alt={"CSA logo"} />
          <Image className="carousel-item" id="logo-villalobos" src={clinicaVeterinariaVillalobosLogos} alt={"Clinica veterinaria Villalobos logo"} />
          <Image className="carousel-item" src={centroVeterinarioJuanCarlosBuiles} alt={"Centro Veterinario Juan Calors Builes"} />
          <Image className="carousel-item" src={mevetLogo} alt={"Mevet logo"} />

        </div>
        <div className="logos-carousel__slide">

          <Image className="carousel-item" src={udcaLogo} alt={"UDCA logo"} />
          <Image className="carousel-item" id="logo-unidad-renal" src={unidadRenalPaolaBaezLogo} alt={"Unidad renal Paola Baez logo"} />
          <Image className="carousel-item" src={bioVetLogo} alt={"biovet logo"} />
          <Image className="carousel-item" src={bioanalisisAnimalLogo} alt={"bio analisis animal logo"} />
          <Image className="carousel-item" id="logo-animal-center" src={animalCenterLogo} alt={"animal center logo"} />
          <Image className="carousel-item" src={laboratiorioCamLogo} alt={"laboratiorio CAM logo"} />
          <Image className="carousel-item" src={zoologicoCaliLogo} alt={"zoologico de Cali logo"} />
          <Image className="carousel-item" src={CSALogo} alt={"CSA logo"} />
          <Image className="carousel-item" id="logo-villalobos" src={clinicaVeterinariaVillalobosLogos} alt={"Clinica veterinaria Villalobos logo"} />
          <Image className="carousel-item" src={centroVeterinarioJuanCarlosBuiles} alt={"Centro Veterinario Juan Calors Builes"} />
          <Image className="carousel-item" src={mevetLogo} alt={"Mevet logo"} />

        </div>

        <div className="logos-carousel__slide" id="extra-carousel-slide">

          <Image className="carousel-item" src={udcaLogo} alt={"UDCA logo"} />
          <Image className="carousel-item" id="logo-unidad-renal" src={unidadRenalPaolaBaezLogo} alt={"Unidad renal Paola Baez logo"} />
          <Image className="carousel-item" src={bioVetLogo} alt={"biovet logo"} />
          <Image className="carousel-item" src={bioanalisisAnimalLogo} alt={"bio analisis animal logo"} />
          <Image className="carousel-item" id="logo-animal-center" src={animalCenterLogo} alt={"animal center logo"} />
          <Image className="carousel-item" src={laboratiorioCamLogo} alt={"laboratiorio CAM logo"} />
          <Image className="carousel-item" src={zoologicoCaliLogo} alt={"zoologico de Cali logo"} />
          <Image className="carousel-item" src={CSALogo} alt={"CSA logo"} />
          <Image className="carousel-item" id="logo-villalobos" src={clinicaVeterinariaVillalobosLogos} alt={"Clinica veterinaria Villalobos logo"} />
          <Image className="carousel-item" src={centroVeterinarioJuanCarlosBuiles} alt={"Centro Veterinario Juan Calors Builes"} />
          <Image className="carousel-item" src={mevetLogo} alt={"Mevet logo"} />

        </div>

      </div>
    </section>
  )
}